import React from "react"
import { Text } from "theme-ui"

import Part from "../../../components/adventures/short-stories/Part"
import Section from "../../../components/adventures/Section"
import Block from "../../../components/adventures/Block"

import tricksterImage from '../../../images/spots/trickster.png'

export default function Part06() {
  return (
    <Part number="6" title="The Trickster's Tower" time="30–40 minutes">
      <Section type="Setup">
        <Block type="Secret Context" title="Setup">
          <Text>In the final scene, the players will face off with The Trickster. If they defeat the final challenge, they will be free to leave the trap with any treasure they still have.</Text>
          <Text>If Lia is with The Party at this stage, they will ask the adventurers to help them rout The Trickster and release the mountain from its Shadow Plane. </Text>
        </Block>
      </Section>

      <Section type="Context">
        <Block type="Secret Context" title="The Tower">
          <Text>You approach two large blue doors at the base of a shiny stone tower that reaches into the clouds. The giant door handles shock your hand when you first touch them. You can tell this is just another one of The Trickster's gags.</Text>
          <Text>The doors swing open, revealing another trick. Instead of the inside of a tower, you are standing on the stage of a giant auditorium. The people of Treasure Town are in attendance, and clap for your arrival. Fiona is sitting in the front row, and makes several marks in her book.</Text>
          <Text>As the doors close behind you, you notice a colorful toy chest on the floor of the stage. When you turn around, the doors have disappeared, revealing that the stage extends into a darkness that surrounds the entire auditorium.</Text>
          <Text>An unknown voice enters your mind.</Text>
        </Block>
      </Section>
      
      <Section
        type="Character"
        name="The Trickster"
        hp="20"
        atk="4"
        specialAbilities={["Phase Shifter"]}
        image={tricksterImage}
        description={
          <>
            <Text>The Trickster is an inter-dimensional traveler who steals treasure and brings it to Mischief Mountain to store. They are vain and cruel, using their treasure hoard to lure in adventurers for entertainment.</Text>
            <Text>The Trickster is proud and arrogant, and will respond to any threat from the adventurers with hostility. They have a short fuse and will not tolerate insults or arrogance on behalf of The Party.</Text>
            <Text>The Trickster will attempt to escape from a confrontation if they are close to defeat. If Lia is present, the Elder Warden will prevent their escape.</Text>
          </>
        }
      />
      
      <Section type="Dialog" cite="The Trickster">
        <Text>“Welcome, honored guests! Can we get some applause going from the audience?</Text>
        <Text>Congratulations on making it this far. Few people actually do!</Text>
        <Text>The final challenge is in the box in front of you. Open it up for a surprise!”</Text>
      </Section>
      
      <Section type="Encounter">
        <Block type="Secret Context" title="Encounter: The Toybox">
          <Text>The Trickster's toybox is a magic device that summons a terrifying illusionary foe. Ask the player who first touches the box to think of their character's most cherished toy as a child. The toy they imagine becomes a monster that appears behind The Party on the stage. For example, if a player says they loved a stuffed teddy bear, a giant teddy bear will appear on stage and attack them; if they imagine a toy sword, you might have a stone golem appear carrying a giant version of the sword.</Text>
          <Text>You can have the creature that is summoned retain some of its unique characteristics. For example, if a teddy bear is summoned, it might have real claws and a fearsome roar. No matter what is summoned, use the same hit points and stats outlined below.</Text>
          <Text>The Trickster is disguised as Fiona using an illusion spell. When the players are finished with the encounter, they will drop the spell and appear in their own form.</Text>
          <Text>Lia will assist The Party in the fight if they are present. The Guide will control Lia's actions during The Party's round of turns.</Text>
        </Block>

        <Block
          type="Character"
          name="The Toy"
          hp="50"
          atk="4"
        />
      </Section>
    </Part>
  )
}
